.header{
  width: 100%;
  height: 80px;
  background-color: #fff;
  position: fixed;
  //filter: drop-shadow(0 2px 8px rgba(171,171,171,.32));
  z-index: 2;
  transition: all 0.5s ease;
  transform: translateY(-100%);
  
  .inner-header{
    width: 100%;
    height: 100%;
    padding: 0 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.header.active {
  transform: translateY(0);
}

.brand{
  width: 20%;
  display: flex;
  img{
    width: 180px;
  }
}

.navbar{
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  .nb-link{
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    .links{
      padding: 0 20px;
      text-align: center;
      transition: all 0.2s ease;
      font-size: 16px;
      &:hover{
        color: #37249a; 
      }
    }
  }
  .lang{
    width: 20%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    transition: all 0.2s ease;
    .lang-btn{
      font-size: 16px;
      &:hover{
        color: #37249a; 
      }
    }
    span{
      padding: 0 5px;
    }
  }
}

.hamburguer{
  display: none;
}

@media (min-width: 800px) and (max-width: 1050px) {
  .header .inner-header{
    padding: 0 40px;
  }
}

@media (min-width: 299px) and (max-width: 799px) {
  .header .inner-header{
    padding: 0 20px;
  }
  
  .hamburguer{
    display: block;
  }

  .navbar{
    position: absolute;
    top: 80px;
    right: 0;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    overflow: hidden;
    border-top: 1px solid #eaeaea;
    transition: all 0.5s ease;
    background-color: #fff;
    transform: translateX(100%);
    max-height: 0;
    .nb-link{
      width: 100%;
      flex-direction: column;
      text-align: center;
      .links{
        font-size: 18px;
        font-weight: 600;
        padding-top: 20px;
        text-transform: uppercase;
      }
    }
    .lang{
      width: 100%;
      justify-content: center;
    }
  }

  .show-nb{
    max-height: 1000px;
    transform: translateX(0);
  }
}

