* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  font-family: 'Poppins', sans-serif;
  color: #333;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

button {
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.general-button{
  padding: 13px 21px;
  font-size: 18px;
  background-color: #37249a;
  border: 1px solid #37249a;
  color: #fff;
  border-radius: 10px;
  text-align: center;
  line-height: 28px;
  font-weight: 500;
  transition: all 0.3s ease;
  &:hover{
    background-color: #fff;
    color: #37249a;
  }
}

h2{
  font-size: 38px;
  line-height: 49px;
  font-weight: 700;
}

@media (min-width: 800px) and (max-width: 1050px) {
  .general-button{
    font-size: 16px;
  }
}

@media (min-width: 299px) and (max-width: 799px) {
  .general-button{
    font-size: 16px;
  }
}