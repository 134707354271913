.footer{
  width: 100%;
  background-color: #181629;
  .inner-footer{
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
}

.f-logos{
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  column-gap: 1%;
  padding: 50px 0;
  a{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 70px;
    height: 70px;
    margin: 0 auto;
    img{
      width: 100%;
      height: 100%;
    }
  }
}

.right{
  width: 100%;
  max-width: 1000px;
  text-align: center;
  padding: 12px 0;
  color: #fff;
  border-top: 1px solid #fff;
  margin: 0 auto;
  font-size: 16px;
}

@media (min-width: 299px) and (max-width: 500px) {
  .f-logos{
    grid-template-columns: 50% 50%;
    row-gap: 20px;
  }

  .right{
    font-size: 14px;
    padding: 16px 0;
  }
}