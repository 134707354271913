.about-us{
  width: 100%;
  background-color: #f4f1ff;
  position: relative;
  background-color: #181629;
  background-image: url(../assets/cinco_seccion.png);
  background-position: center; 
  background-repeat: no-repeat; 
  background-size: cover;
  .inner-about-us{
    width: 100%;
    height: auto;
    max-width: 1600px;
    margin: 0 auto;
    padding: 80px 60px;
    img{
      width: 400px;
    }
  }
}

.about-us-items{
  margin-top: 40px;
  display: grid;
  grid-template-columns: 32% 32% 32%;
  gap: 2%;
  .au-item{
    border-radius: 10px;
    padding: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: #181629db;
    h3{
      font-weight: 700;
      font-size: 21px;
      line-height: 34px;
      padding: 12px 0;
      color: #fff;
    }
    p{
      font-weight: 300;
      font-size: 16px;
      line-height: 23px;
      color: #fff;
    }
    a{
      color: #fff;
      text-decoration: underline;
    }
  }
}

#about-us {
  position: absolute;
  top: -80px;
}

@media (min-width: 800px) and (max-width: 1050px) {
  .about-us .inner-about-us{
    padding: 40px 40px;
  }
}

@media (min-width: 299px) and (max-width: 799px) {
  .about-us .inner-about-us{
    padding: 40px 20px;
    img{
      width: 300px;
    }
  }

  .about-us-items{
    grid-template-columns: 100%;
    gap: 0;
    .au-item{
      margin-bottom: 15px;
      h3{
        font-size: 20px;
      line-height: 30px;
      }
      p{
        font-size: 15px;
      }
    }
  }
}