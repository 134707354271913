.contact{
  width: 100%;
  position: relative;
  .inner-contact{
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    padding: 80px 60px;
  }
}

.contact-container{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.form{
  width: 50%;
  max-width: 600px;
  form{
    display: flex;
    flex-direction: column;
    label{
      font-size: 16px;
      font-weight: 500;
      padding-bottom: 3px;
    }
    input{
      height: 45px;
      border: 1px solid #d4d4d4;
      margin-bottom: 18px;
      padding-left: 8px;


    }
    textarea{
      height: 55px;
      border: 1px solid #d4d4d4;
      padding: 8px;
    }
  }
}

.form-btn{
  margin-top: 18px;
}

.support{
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .s-title{
    display: flex;
    text-transform: uppercase;
    h3{
      padding-left: 5px;
    }
  }
  p{
    padding: 3px 0;
    font-size: 13px;
  }
}

#contact {
  position: absolute;
  top: -60px;
}

@media (min-width: 800px) and (max-width: 1050px) {
  .contact .inner-contact{
    padding: 60px 40px;
  }
}
  

@media (min-width: 299px) and (max-width: 799px) {
  .contact .inner-contact{
    padding: 60px 20px;
  }

  .contact-container{
    flex-direction: column-reverse;
  }

  .form{
    width: 100%;
    margin-bottom: 30px;
  }

  .support{
    width: 100%;
  }
}

