.cover{
  width: 100%;
  height: 100vh;
  background-color: #181629;
  .inner-cover{
    width: 100%;
    height: 100%;
    max-width: 1600px;
    margin: 0 auto;
    padding: 40px 60px 0 60px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.text-cover{
  width: 50%;
  h1{
    font-size: 64px;
    line-height: 64px;
    font-weight: 700;
    margin-bottom: 35px;
    color: #fff;
  }
}

.img-cover{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    width: 100%;
    max-width: 600px;
    height: auto;
    object-fit: cover;
  }
}

@media (min-width: 800px) and (max-width: 1050px) {
  .cover .inner-cover{
    padding: 0 40px;
  }

  .text-cover{
    h1 { 
      font-size: 46px; 
    }
  } 
}

@media (min-width: 299px) and (max-width: 799px) {
  .cover .inner-cover{
    padding: 0 20px;
    flex-direction: column;
  }

  .text-cover{
    width: 100%;
    padding-top: 120px;
    h1 { 
      font-size: 38px; 
      line-height: 48px;
      margin-bottom: 20px;
    }
  } 

  .img-cover{
    width: 100%;
    img{
      width: 250px;
    }
  }
}