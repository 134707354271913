.tecnology{
  width: 100%;
  background-color: #d1c6ff;
  position: relative;
  .inner-tecnology{
    width: 100%;
    max-width: 1600px;
    padding: 60px 60px;
    margin: 0 auto;
    h2{
      font-size: 34px;
    }
   p {
      font-size: 16px;
      padding-top: 12px;
      max-width: 850px;
  }
  }
}


@media (min-width: 800px) and (max-width: 1050px) {
  .tecnology .inner-tecnology{
    padding: 60px 40px;
    h2{
      font-size: 26px;
    }
    p{
      font-size: 14px;
      padding-top: 8px;
    }
  }
}

@media (min-width: 299px) and (max-width: 799px) {
  .tecnology .inner-tecnology{
    padding: 60px 20px;
    text-align: center;
    h2{
      font-size: 26px;
      line-height: 36px;
    }
    p{
      font-size: 14px;
      padding-top: 14px;
    }
  }
}