.how-it-works{
  width: 100%;
  height: 100vh;
  position: relative;
  .inner-how-it-works{
    width: 100%;
    height: 100%;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 60px 0 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.hiw-img{
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  img{
    width: 100%;
    max-width: 600px;
    height: auto;
    zoom: 4;
  }
}

.hiw-text{
  width: 50%;
  p{
    max-width: 550px;
    font-size: 18px;
    margin: 10px 0 40px 0;
  }
}

.hiw-items{
  width: 100%;
  .item{
   width: 100%;
   height: auto;
   display: flex;
   flex-direction: row;
   align-items: flex-start;
   margin-bottom: 30px;
   img{
     width: 20px;
   }
   .item-text{
     margin-left: 8px;
     h3{
       line-height: 1;
       font-size: 16px;
     }
    p{
      margin: 0;
      padding-top: 8px;
      font-size: 14px;
    }
   }
  }
}


#how-it-works {
  position: absolute;
  top: -60px;
}

@media (min-width: 800px) and (max-width: 1050px) {
  .how-it-works .inner-how-it-works{
    padding: 60px 40px;
  }

  .hiw-text{
    h2{
      font-size: 34px;
    }
    p{
      font-size: 14px;
      margin: 10px 0 30px 0;
    }
  }

  .hiw-items .item{
    margin-bottom: 25px;
    .item-text{
      h3{
        font-size: 16px;
      }
      p{
        font-size: 13px;
      }
    }
  } 
}

@media (min-width: 299px) and (max-width: 799px) {
  .how-it-works{
    height: auto;
    .inner-how-it-works{
      padding: 60px 20px;
      flex-direction: column;
    }
  } 

  .hiw-img{
    width: 100%;
  }

  .hiw-text{
    width: 100%;
    h2{
      font-size: 34px;
    }
    p{
      font-size: 14px;
      margin: 10px 0 30px 0;
    }
  }

  .hiw-items .item{
    margin-bottom: 23px;
    .item-text{
      p{
        font-size: 13px;
      }
    }
  } 
}
